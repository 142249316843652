import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
  Select,
  Box,
  Flex,Image,
  Text,Button,Card,CardBody,SimpleGrid,
  Textarea
} from "@chakra-ui/react";
import Webcam from 'react-webcam';
import axios from 'axios';
import {useSelector} from 'react-redux'
import room1 from '../../assets/img/room-1.jpg'
import room2 from '../../assets/img/room-2.jpg'
import room3 from '../../assets/img/room-3.jpg'
import room4 from '../../assets/img/room-1.jpg'
import room5 from '../../assets/img/room-2.jpg'
import room6 from '../../assets/img/room-3.jpg'
import "react-calendar/dist/Calendar.css";
import Calendar from "react-calendar";
import React, { useState,useEffect,useRef } from "react";


 
  
import toast from "react-hot-toast";
  const array = [
    { src: room1, label: 'Room 1' },
    { src: room2, label: 'Room 2' },
    { src: room3, label: 'Room 3' },
    { src: room4, label: 'Room 4' },
    { src: room5, label: 'Room 5' },
    { src: room6, label: 'Room 6' },
  ];
  



  const RoomBook = () => {
    const webcamRef=useRef(null)
    const customerId=localStorage.getItem('customerId')
  console.log(customerId);
  const [roomData,setRoomData]=useState([]);
  const [roomBook,setRoomBook]=useState([]);
 // const [dueAmount, setDueAmount] = useState(0); 
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedadharFile, setSelectedadharFile] = useState(null);
  const [selectedpanFile, setSelectedpanFile] = useState(null);
  const [selecteddrivingFile, setSelecteddrivingFile] = useState(null);
const[imageURL,setImageURL]=useState(null);
const[drivingLicense,setDrivingLicense]=useState(null);
const[panNo,setPanNo]=useState(null);
const [uploading, setUploading] = useState(false);
const [adharuploading, setadharUploading] = useState(false);
const [panuploading, setpanUploading] = useState(false);
const [driveruploading, setdriverUploading] = useState(false);
const [otheruploading, setotherUploading] = useState(false);
  const [printData,setPrintData]=useState([]);
  const [imageCapture,setImageCapture]=useState(null)
  
  const [toggle,setToggle]=useState(false)
  const fetchData=async()=>{
      const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomdata/${customerId}`)
      setRoomData(response.data)
  }
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }
  // const handleFileChange1 = (event) => {
  //   setSelectedadharFile(event.target.files[0]);
  // }
  // const handleFileChange2 = (event) => {
  //   setSelecteddrivingFile(event.target.files[0]);
  // }
  // const handleFileChange3 = (event) => {
  //   setSelectedpanFile(event.target.files[0]);
  // }
   
    
  const handleOtherChange = (index, event, isFile = false) => {
    const { name } = event.target;
    const value = isFile ? event.target.files[0] : event.target.value;
  
    setFormData((prev) => {
      const updatedOther = [...prev.other];
      updatedOther[index] = {
        ...updatedOther[index],
        [name]: value,
      };
      return { ...prev, other: updatedOther };
    });
  }
  
  const fetchData3=async()=>{
    console.log("Hi")
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setPrintData(response.data)
    const rooms = response.data;
    console.log(rooms,'rooms')
    const updatedRooms = await Promise.all(
      rooms?.map(async (room) => {
        const imageResponse = await axios.get('https://api.hotel.tdrsoftware.in/image', {
          params: { customerId, key: room.image }
        });
        room.imageUrl = imageResponse.data;
        return room;
      })
    );
  }
 console.log(printData,"print")
 
let room=printData?.map((item)=>item.roomType);
const roomTypes = room[0]?.split(',')?.map(item => item.trim());
console.log(roomTypes,'roomTypes')
 // const editId=localStorage.getItem("EditId")
    // const [data1, setData1] = useState(new Date());
    // console.log(data1.toDateString());
    // const [data2, setData2] = useState(new Date());
    // console.log(data2.toDateString());
    // const [total, setTotal] = useState(0);
    // const [advance, setAdvance] = useState(0);
    //let dueAmount = 0;
   // console.log("room"+customerId)
   
   const handleRoomAvailability = async () => {
    if (!validateForm()) {
      toast.error("Please fill in all required fields.");
      return;
    }
  
    try {
      const availabilityRequests = selectedRooms?.map(async (room) => {
        const response = await axios.put(`https://api.hotel.tdrsoftware.in/api/updateAvailability/${customerId}/${room._id}`);
        console.log(response.data);
        toast.success(response.data.message);
        return response;
      });
  
      // Wait for all availability requests to complete
      const responses = await Promise.all(availabilityRequests);
      console.log("All rooms availability updated:", responses);
  
    } catch (error) {
      console.error("Error updating room availability:", error);
      toast.error("Failed to update room availability. Please try again.");
    }
  };
 


      const handleRoomSelectChange = (e) => {
        const roomNo = e.target.value;
        const selectedRoom = roomData.find((room) => room.roomNo === roomNo);
    
        if (selectedRoom) {
          // Check if the room is already selected
          if (!selectedRooms.some((room) => room.roomNo === selectedRoom.roomNo)) {
            setSelectedRooms([...selectedRooms, selectedRoom]);
          } else {
            // Room already selected, remove it from selectedRooms
            setSelectedRooms(selectedRooms.filter((room) => room.roomNo !== roomNo));
          }
        }
      };
    
    console.log(roomBook.roomNo,'roomBook')

    //console.log(roomData)
    const [dueAmount, setDueAmount] = useState(0); 
    const [selectedRooms, setSelectedRooms] = useState([]);;
    const [itemList,setItemList]=useState([])
    const [formData, setFormData] = useState({
      name: "",
      nationality:"",
      registration:"2024-",
      date: "",
      address:"",
      email:"",
     phoneNo:"",
      adhar: "",
      gstNo: "",
      pax:'',
      adharImage:'',
      drivingLicense:"",
      panNo:"",
      adult:'',
      child:'',
      checkIn:"",
      checkOut:'',
      expiryPassport:"",
      roomNo: "",
      floorNo: "",
      image:null,
      category:'',
      arrivedFrom:'',
      nextDestination:'',
      ac: "",
      id:'',
      purpose:'',
      passport:'',
      issue:'',
      arrival:'',
      placeOfIssue:'',
      stay:'',
      visa:'',
      issueVisa:'',
      expiry:'',
      other:[]
      
    });
    let roomNumber=localStorage.getItem('RoomNo')
    const capture=()=>{
      const imageShot=webcamRef.current.getScreenshot();
      setImageCapture(imageShot)
    }
   
    useEffect(()=>{
      
      fetchData();
      fetchData3();
    },[customerId])
    console.log(roomData,"roomData")
    const handleToggle=()=>{
      if(formData.nationality === "Others"){
        setToggle(!toggle)
      }
     }
      
  const handleDueFocus = () => {
    setFormData({
      ...formData,
      child: formData.pax - formData.adult,
    });
  };
     console.log(formData.nationality,"nationality")
     console.log(toggle)
    const validateForm = () => {
      const requiredFields = [
        "name",
        "registration",
        "date",
        "nationality",
        "address",
       "phoneNo",
        
        "arrivedFrom",
        "nextDestination",
        "checkIn",
        "checkOut",
        
       
        "category",
       
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
    // const handleOtherChange = (index, e) => {
    //   const { name, value } = e.target;
    //   const updatedOther = [...formData.other];
    
    //   // Ensure the field exists before updating
    //   if (updatedOther[index]) {
    //     updatedOther[index][name] = value;
    //   }
    
    //   setFormData({
    //     ...formData,
    //     other: updatedOther,
    //   });
    // };
    
    // const handlePaxChange = (e) => {
    //   const paxValue = parseInt(e.target.value, 10); // Make sure it's parsed as a number
    //   setFormData({
    //     ...formData,
    //     pax: paxValue,
    //     other: Array(paxValue - 1).fill({ name: '', adharNo: '', relation: '' }), // Adjust for paxValue
    //   });
    // };
    const handlePaxChange = (e) => {
      const paxValue = e.target.value === '' ? '' : parseInt(e.target.value, 10); // Handle empty input
    
      // Create a unique object for each guest in the other array if paxValue is valid
      const otherGuests = paxValue && paxValue > 1
        ? Array.from({ length: paxValue - 1 }, () => ({ name: '', adharNo: '', relation: '', adharImageOfOther: '' }))
        : [];
    
      setFormData({
        ...formData,
        pax: paxValue,
        other: otherGuests,
      });
    };
    console.log(formData,'other')
    const generateRandomRegistration = () => {
      const year = new Date().getFullYear(); // Get current year
      const randomDigits = Math.floor(10000 + Math.random() * 90000); // Generate random 5-digit number
      return `${year}-${randomDigits}`;
    };
    const handlePost = async () => {
      try {
        setUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        const base64ToBlob = (base64) => {
          const byteString = atob(base64.split(',')[1]); // Extract base64 data
          const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // Get the mime type
          const ab = new ArrayBuffer(byteString.length);
          const ia = new Uint8Array(ab);
          for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
          }
          return new Blob([ab], { type: mimeString });
        };
    
        const imageBlob = base64ToBlob(imageCapture); // Convert the Base64 image to Blob
        formData.append('image', imageBlob);   // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        console.log(data,'data')
        setFormData((prev)=>({
          ...prev,
          image:Object.values(data).join()
  
        }))
      //  setImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setUploading(false);
      }
      
    };
    const handlePost1 = async () => {
      try {
        setadharUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setImageURL(Object.values(data).join());
     toast.success("Image uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setadharUploading(false);
      }
      
    };
    const handleOtherPost = async (index) => {
      try {
        setotherUploading(true);
        const formData = new FormData();
        formData.append("customerId", customerId);
        formData.append("image", selectedFile); // Ensure the field name is 'image'
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
    
        const data = response.data;
        const imageURL = Object.values(data).join();
    
        // Update the specific index in formData.other
        setFormData((prev) => {
          const updatedOther = [...prev.other];
          updatedOther[index] = {
            ...updatedOther[index],
            adharImageOfOther: imageURL, // Set the uploaded image URL
          };
          return { ...prev, other: updatedOther };
        });
    
        toast.success("Image uploaded successfully");
      } catch (err) {
        toast.error(err.message);
      } finally {
        setotherUploading(false);
      }
    };
    const handlePost2 = async () => {
      try {
        setdriverUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setDrivingLicense(Object.values(data).join());
     toast.success("Driving License uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setdriverUploading(false);
      }
      
    };
    const handlePost3 = async () => {
      try {
        setpanUploading(true);
        const formData = new FormData();
        formData.append('customerId', customerId);
        formData.append('image', selectedFile);  // Ensure the field name is 'image' here
    
        const response = await axios.post("https://api.hotel.tdrsoftware.in/images", formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        });
        const data = response.data;
        setPanNo(Object.values(data).join());
     toast.success("Pan uploaded successfully");
        console.log(data,'data')
      } catch (err) {
        toast.error(err);
      } finally {
        setpanUploading(false);
      }
      
    };

    

    // const handleRoomChange = (e) => {
    //   const selected = roomData.find(item => item.category === e.target.value);
    //   if (selected) {
    //     setFormData({
    //       ...formData,
    //       roomNo: selected.roomNo,
          
          
          

    //     });
    //   }
    //   console.log(formData);
    // };
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const bookedRoomNos=roomData.filter((item)=>{
      if(item.availability == true){
        if(item.category === formData.category && item.ac === formData.ac){
          return item.roomNo
        }
      }
    })
    const handleSubmit =async (e) => {
      e.preventDefault();
      // Here you can send formData to your backend server or process it further
     
      // Reset form after submission
      
      setFormData({
          name: "",
          registration:"",
          date: "",
          address:"",
         phoneNo:"",
          adhar: "",
          gstNo: "",
          checkIn:"",
          checkOut:'',
          roomNo: "",
         
          category:'',
          ac: "",
          id:"",
       
      });
   
      
    };
    console.log(formData.id,'id')
    const save = async (e) => {
      e.preventDefault();
    
      if (!validateForm()) {
        toast.error("Please fill in all required fields.");
        return;
      }
    
      const updatedFormData = {
        ...formData,
        pax:formData.pax,
        adult:formData.adult,
        child:formData.child,
        adharImage:imageURL,
        drivingLicense:drivingLicense,
        panNo:panNo,
        registration: generateRandomRegistration()
      };
    
      try {
        // Update formData state with the new registration number
        setFormData(updatedFormData);
    
        // Send POST request with updatedFormData
        

        
        for (const room of selectedRooms) {
          const response = await axios.post(
            "https://api.hotel.tdrsoftware.in/api/roomBook",
            {
              customerId: customerId,
              data: {
                ...updatedFormData,
                roomNo: room.roomNo,
                floorNo: room.floorNo,
                category: room.category,
                ac: room.ac,
                id: room._id,
              },
            }
          );
          console.log("Room booked:", response.data);
          toast.success(response.data.message);
        }
    
    
        // Clear form fields after successful submission
        setFormData({
          name: "",
          registration: "",
          date: "",
          address: "",
          email:"",
          phoneNo: "",
          nationality: "",
          purpose: "",
          arrivedFrom: "",
          nextDestination: "",
          pax:'',
          adult:'',
          child:'',
          adhar: "",
          gstNo: "",
          checkIn: "",
          checkOut: '',
          expiryPassport:"",
          roomNo: "",
          floorNo: "",
          category: '',
          ac: "",
          id: "",
          drivingLicense:"",
          panNo:""
          
        });
        setSelectedRooms([])
       // window.location.reload()
      } catch (error) {
        console.log(error);
        if(error.response && error.response.status === 404){
          toast.error("Room is already booked in that date range")
        }else{
        toast.error("Failed to submit data. Please try again.");
        }
      }
    };
  console.log(formData);
  let arr=Object.entries(formData)
    // useEffect(() => {
    //   // Calculate due amount whenever relevant form fields change
    //   // const calculateDue = () => {
    //   //   const { totalAgreement, advance } = formData;
    //   //   const due = parseInt(totalAgreement) - parseInt(advance);
    //   //   setDueAmount(isNaN(due) ? 0 : due);
    //   // };
    //   // calculateDue();
    // }, [formData.totalAgreement, formData.advance]);
    
    // due=totalAgreement-advance
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      color='black' width='100%' height='fit-content'   >
        
        <FormControl isRequired onSubmit={handleSubmit}>
          <Box display="grid"  gridGap={1} >
            <Flex
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
             
            >
              <Box width="30%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Name</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter name"  name='name' value={formData.name} onChange={handleInputChange} />
              </Box>
              <Box width="30%"  display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              
              p={4}>
                <FormLabel  >Email</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Email"  name='email' value={formData.email} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Date</FormLabel>
                <Input type='date' w='60%'  bg='#FAFAFA ' ac="text" placeholder="Enter Date" name='date' value={formData.date} onChange={handleInputChange} />
              </Box>
            </Flex>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Nationality</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Select Nationality" name='nationality' onClick={handleToggle} value={formData.nationality} onChange={handleInputChange}>
                  <option value={"Indian"}>Indian</option>
                  <option value={"Others"}>Others</option>
                </Select>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Purpose</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Purpose of visit " name='purpose' value={formData.purpose} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box display='flex' flexDirection='row' justifyContent='center' alignItems='center' >
              <Box padding='5' display='flex' flexDirection='column' gap='5'>
              <Webcam
        audio={false}
        ref={webcamRef}
        screenshotFormat="image/jpeg"
        width={320}
        height={240}
      />

      {/* Button to capture image */}
      <Button colorScheme="gray" onClick={capture}>Capture Photo</Button>
              </Box>
            

      {/* Display the captured image */}
      {imageCapture && (
        <Box padding='10' mb='10' >
          <h3>Captured Image:</h3>
          <img src={imageCapture} alt="Captured" />
          <Button variant='solid' onClick={handlePost} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
        </Button>
        </Box>
      )}
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Adhar" name='adhar' value={formData.adhar} onChange={handleInputChange}/>
                  
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar Image</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost1} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
             
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Driving License</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost2} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                  
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Pan No</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA ' onChange={handleFileChange}  />
                <Button variant='solid' onClick={handlePost3} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
             
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              {/* <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Adhar" name='adhar' value={formData.adhar} onChange={handleInputChange}/>
                  
              </Box> */}
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Registration</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Enter registration" name='registration' value={formData.registration} onChange={handleInputChange}/>
                  
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>PhoneNo</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Enter Phoneno" name='phoneNo' value={formData.phoneNo} onChange={handleInputChange}/>
                  
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Arrived From</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Arrived From" name='arrivedFrom' value={formData.arrivedFrom} onChange={handleInputChange}/>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Next Destination</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Next Destination" name='nextDestination' value={formData.nextDestination} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Address</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Address" name='address' value={formData.address} onChange={handleInputChange}/>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CheckIn</FormLabel>
                <Input type='date'w='70%' bg='#FAFAFA ' placeholder="Enter CheckIn" name='checkIn' value={formData.checkIn} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>CheckOut</FormLabel>
                <Input type='date' w='67%' bg='#FAFAFA ' placeholder="Enter Checkout" name='checkOut' value={formData.checkOut} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select  w='66%' bg='#FAFAFA ' placeholder="Enter Category" name='category' value={formData.category} onChange={handleInputChange} >
                {roomTypes?.map((item,index)=>(
                  <option key={index} value={item}>{item}</option>
                ))}
                </Select>
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Ac/Non-Ac</FormLabel>
                <Select  w='47%' bg='#FAFAFA ' placeholder="Ac/Non-Ac" name='ac' value={formData.ac} onChange={handleInputChange} >
                  <option value={'AC'}>Ac</option>
                  <option value={'Non-AC'}>Non-Ac</option>
                </Select>
                
              </Box>
              
              
              
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>PAX</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter Capacity" name='pax' value={formData.pax} onChange={handlePaxChange}/>
                  
                 
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adult</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Adult Capacity" name='adult' value={formData.adult} onChange={handleInputChange}/>
                  
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Child</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Child Capacity" name='child' value={formData.child} onFocus={handleDueFocus} onChange={handleInputChange}/> 
                  
                  
               
              </Box>
            </Box>
           
               {formData.other?.map((person,index)=>(
                <Box key={index} display="flex"
                gap="10px"
                alignItems="center"
                justifyContent="space-evenly">
                  <Box width="30%" display="flex"  alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Name</FormLabel>
                <Input bg='#FAFAFA' width='60%' name='name' value={person.name} onChange={(e)=>handleOtherChange(index,e)}/>

              </Box>
              {/* <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar</FormLabel>
                <Input bg='FAFAFA' width='30%' type='file' value={person.adharImage} onChange={handleFileChange}/>
                <Button variant='solid' onClick={handlePost1} disabled={uploading}>
        {uploading ? "Uploading..." : "Upload"}</Button>

              </Box> */}
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar</FormLabel>
                <Input bg='#FAFAFA' width='62%' name='adharNo' color='black' value={person.adharNo} onChange={(e)=>handleOtherChange(index,e)}/>

              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Adhar Image</FormLabel>
                <Input w='70%' type="file" bg='#FAFAFA '  onChange={handleFileChange} / >
                <Button variant='solid' onClick={()=>handleOtherPost(index)} disabled={uploading} >
        {uploading ? "Uploading..." : "Upload"}
      </Button>
                
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Relation</FormLabel>
                <Input bg='#FAFAFA' width='50%' name='relation' value={person.relation} onChange={(e)=>handleOtherChange(index,e)}/>

              </Box>
                  </Box>
               ))} 

           
           
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Room No</FormLabel>
                <Select  w='70%' bg='#FAFAFA ' placeholder="Enter RoomNo" name='roomNo' value={formData.roomNo} onChange={handleRoomSelectChange} >
                  {bookedRoomNos?.map((item)=>(
                      <option key={item._id}>{item.roomNo}</option>
                  ))}
                </Select>
                
               
              </Box>
               <Box
            width="40%"
            display="flex"
            alignItems="center"
            boxShadow="xl"
            borderRadius="5px"
            p={4}
          >
            <FormLabel>Selected Rooms</FormLabel>
            <Box display='flex' flexDirection='row' bg='white' borderRadius='6px' p={2} >
              {selectedRooms?.map((room) => (
                <Text    key={room._id}>{room.roomNo+"-"}</Text>
              ))}
            </Box>
          </Box>
        
            </Box>
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
             
              
            </Box>
            {toggle ? <Box   display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={5} >
              <Text as='b' size='lg' mt={10} mb={10}>Foreigners Only</Text>
              <Box
              width='full'
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Passport No</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Enter PassportNo" name='passport'  value={formData.passport} onChange={handleInputChange} />
                 
                
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Issue Date</FormLabel>
                <Input type='date'w='70%' bg='#FAFAFA ' placeholder="Issue Date " name='issue' value={formData.issue} onChange={handleInputChange} />
              </Box>
              <Box width="30%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Expiry Passport</FormLabel>
                <Input type='date'w='70%' bg='#FAFAFA ' placeholder="Expiry Passport" name='expiryPassport' value={formData.expiryPassport} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              width='full'
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Date of Arrival</FormLabel>
                <Input type='date' w='60%' bg='#FAFAFA ' placeholder="Enter Date of Arrival" name='arrival'  value={formData.arrival} onChange={handleInputChange} />
                 
                
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Place of Issue</FormLabel>
                <Input w='60%' bg='#FAFAFA ' placeholder="Enter Place Of Issue " name='placeOfIssue' value={formData.placeOfIssue} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              width='full'
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Duration of Stay In India</FormLabel>
                <Input w='40%' bg='#FAFAFA ' placeholder="Enter Duration " name='stay'  value={formData.stay} onChange={handleInputChange} />
                 
                
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Visa No</FormLabel>
                <Input w='70%' bg='#FAFAFA ' placeholder="Issue Date " name='visa' value={formData.visa} onChange={handleInputChange} />
              </Box>
            </Box>
            <Box
              width='full'
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Issue Date of Visa</FormLabel>
                <Input w='70%' bg='#FAFAFA ' type='date' placeholder="Issua Date of Visa" name='issueVisa'  value={formData.issueVisa} onChange={handleInputChange} />
                 
                
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Expiry  Date</FormLabel>
                <Input type='date'w='70%' bg='#FAFAFA ' placeholder="Expiry Date of Visa " name='expiry' value={formData.expiry} onChange={handleInputChange} />
              </Box>
            </Box>
              </Box>:""}
            </Box>
            
            
            
           
          
          {/* <Box display='flex' flexDirection='column' rowGap='10px' mt={4}>
          <Text fontSize='32px' fontWeight='bold'>Floor</Text>
          <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="center"
              
            >
           
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel >Floor</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Enter Category" name='floorNo' value={formData.floorNo} onChange={handleInputChange} >
                  <option>Ground Floor</option>
                  <option>First Floor</option>
                  <option>Second Floor</option>
                  <option>Third Floor</option>
                </Select>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Category</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Deluxe or Non-Deluxe" name='category' value={formData.category} onChange={handleInputChange} >
                  <option>Deluxe</option>
                  <option>Non-Deluxe</option>
                </Select>
              </Box>
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel>Image</FormLabel>
                <Select w='70%' bg='#FAFAFA ' placeholder="Enter Image" name='floorImage' value={formData.floorImage} onChange={handleInputChange} >
                  {array.map((item, index) => (
                    
                      <option key={index} value={item.src}>{item.label}</option>
                  
                  ))}
                </Select>
                
              </Box>
              </Box>
              {formData.floorImage && (
            <Flex justifyContent="center" mt={4}>
              <Image src={formData.floorImage} alt="Selected Room" h="300px" objectFit="cover" w='40%' />
            </Flex>
          )}
              </Box>

          </Box>
          */}
          {/* <Button ac="submit" variant='solid' mr={4} color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={()=>handleRoomAvailability(formData.id)}>Room Book </Button> */}
          <Button ac="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>Save</Button>
        </FormControl>
        
      </Box>
    );

  

  }





export default RoomBook