import React,{useRef} from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Text,
  SimpleGrid,
  Box,
  Input,
  FormLabel,Select,
  InputGroup,
  InputLeftAddon,CardHeader,CardFooter,Card,CardBody,
  Image

} from '@chakra-ui/react';
import { useReactToPrint } from 'react-to-print';
import { useState,useEffect } from 'react';
import { IoMdClose } from "react-icons/io";
import axios from 'axios'
import toast from 'react-hot-toast';

const ForeignReportModal = ({ isOpen, onClose, toggle1,setToggle1,registration,id,customerId,data,isForeignReport,setIsForeignReport}) => {
  //  const [data,setData]=useState([])
 // const componentRef = useRef();
    const [formData,setFormData]=useState({
      nameAndAddressOfHotel: '',
      phoneOfHotel:'',
      nameOfForeigner:"",
      dob:"",
      foreignerAddress:"",
      foreignerAddressInIndia:"",
      nationality:"",
      passportNo:"",
      placeIssueOfPassport:"",
      dateIssueOfPassport:"",
      expiryDateOfPassport:'',
      visaNo:"",
      visaType:"",
      placeIssueOfVisa:"",
      arrivedFrom:"",
      dateOfArrivalIndia:"",
      dateOfArrivalHotel:"",
      timeOfArrivalHotel:"",
      durationInHotel:"",
      employedInIndia:"",
      purposeOfVisit: "",
      nextDestination: "",
      phoneNoOfForeigner: "",
      expiryDateOfVisa:"",
      permanentCountryOfForeigner: "",
      imageOfGuest: "",
         
    
    
    })
    const componentRef = useRef();
    
   
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    let arr=Object.entries(formData)
    console.log(arr[0],arr)
    console.log(formData)
 

    
    // const fetchData=async()=>{
        
    //     try {
    //         const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoomBookDataById/${customerId}/${id}`)
    //         setData(response.data)
    //     } catch (error) {
            
    //     }
    // }
   // console.log(data.name,'data')
    // useEffect(()=>{
    //     fetchData()
    // },[customerId])

    useEffect(() => {
        if (data) {
            setFormData((prev)=>({...prev,
              nameOfForeigner: data.name || "",
                
                nationality: data.nationality || "",
                dateOfArrivalHotel:data.date || "",
                purposeOfVisit:data.purpose || "",
               


                arrivedFrom: data.arrivedFrom || '',
                nextDestination: data.nextDestination || "",
                foreignerAddress: data.address || "",
                phoneNoOfForeigner: data.phoneNo || "",
                
                passportNo: data.passport || "",
                placeIssueOfPassport: data.placeOfissue || "",
                dateIssueOfPassport: data.issue || "",
                
                expiryDateOfPassport:data.expiryPassport || "",
                stay: data.stay || "",
                visaNo: data.visa || "",
                
                expiryexpiryDateOfVisa: data.expiry || "",
                imageOfGuest:data.image || ""
            }));
        }
    }, [data]);
    const scroll='inside'
   
    const handleInputChange=(e)=>{
     const {name,value}=e.target;
     setFormData({
        ...formData,
       [name] : value 
     })
    }
    const handleSubmit=(e)=>{
      e.preventDefault()
      setFormData({
        nameAndAddressOfHotel: formData.nameAndAddressOfHotel,
        phoneOfHotel:formData.phoneOfHotel,
        nameOfForeigner:formData.nameOfForeigner,
        dob:formData.dob,
        foreignerAddress:formData.foreignerAddress,
        nationality:formData.nationality,
        passportNo:formData.passportNo,
        placeIssueOfPassport:formData.placeIssueOfPassport,
        dateIssueOfPassport:formData.dateIssueOfPassport,
        expiryDateOfPassport:formData.expiryDateOfPassport,
        visaType:formData.visaType,
        placeIssueOfVisa:formData.placeIssueOfVisa,
        arrivedFrom:formData.arrivedFrom,
        dateOfArrivalIndia:formData.dateOfArrivalIndia,
        dateOfArrivalHotel:formData.dateOfArrivalHotel,
        timeOfArrivalHotel:formData.timeOfArrivalHotel,
        durationInHotel:formData.durationInHotel,
        employedInIndia:formData.employedInIndia,
        purposeOfVisit: formData.purposeOfVisit,
        nextDestination: formData.nextDestination,
        phoneNoOfForeigner: formData.phoneNoOfForeigner,
        permanentCountryOfForeigner: formData.permanentCountryOfForeigner,
        imageOfGuest: formData.imageOfGuest,
    })
       
    }
    const Save = async (e) => {
      e.preventDefault();
      const updatedFormData=()=>{
        setFormData((prev)=>({
          ...prev,
          nameAndAddressOfHotel:formData.nameAndAddressOfHotel,
          dateOfArrivalIndia:formData.dateOfArrivalIndia,





          
        }))
      }
      
      try {
          // Make the PUT request with formData directly
          const response = await axios.post(`https://api.hotel.tdrsoftware.in/api/foreignerReport`,
           { customerId:customerId,data: formData});
          
          toast.success(response.data.message);
          console.log(response, 'response');
          
          // Optionally reset the form after a successful update
          setFormData({
            nameAndAddressOfHotel: '',
            phoneOfHotel:'',
            nameOfForeigner:"",
            dob:"",
            foreignerAddress:"",
            foreignerAddressInIndia:"",
            nationality:"",
            passportNo:"",
            placeIssueOfPassport:"",
            dateIssueOfPassport:"",
            expiryDateOfPassport:'',
            visaNo:"",
            visaType:"",
            placeIssueOfVisa:"",
            arrivedFrom:"",
            dateOfArrivalIndia:"",
            dateOfArrivalHotel:"",
            timeOfArrivalHotel:"",
            durationInHotel:"",
            employedInIndia:"",
            purposeOfVisit: "",
            nextDestination: "",
            phoneNoOfForeigner: "",
            permanentCountryOfForeigner: "",
            imageOfGuest: "",
          });
          window.location.reload();
  
      } catch (error) {
          toast.error("Error updating room book: " + error.message);
          console.error("Update failed:", error);
      }
  };
    // const handleClick=()=>{
    //    setToggle1(!toggle1)
    //   // setBookingData([])
    // }
  return (
    <Modal isOpen={isOpen} onClose={onClose} width='full' size='3xl' scrollBehavior={scroll}  >
      <ModalOverlay />
      <ModalContent bg='#f9f1e3'>
        <ModalHeader display='flex' justifyContent='space-between' alignItems='center'>
            <Text>Foreign Report {data.name}</Text>
            <Button colorScheme='red' onClick={()=>{onClose();setIsForeignReport(!isForeignReport)}} ><IoMdClose/></Button></ModalHeader>
       
        <ModalBody >
         <SimpleGrid columns={2} gap={4} placeItems='left'>
            <InputGroup>
            <InputLeftAddon>Hotel Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='nameAndAddressOfHotel' placeholder='Name and Address of Hotel' value={formData.nameAndAddressOfHotel} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Hotel PhoneNo</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='nameOfForeigner' placeholder='Name of Foreigner' value={formData.nameOfForeigner} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Name</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='phoneOfHotel' placeholder='Enter PhoneNo of Hotel' value={formData.phoneOfHotel} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Date Of Birth</InputLeftAddon>
           <Input  bg='white' type='date' onChange={handleInputChange} name='dob' placeholder='Enter Date of Birth' value={formData.dob} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Nationality</InputLeftAddon>
           <Input width='70%' bg='white' onChange={handleInputChange} name='nationality' placeholder='Enter nationality' value={formData.nationality} />
          
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Foreigner Address</InputLeftAddon>
           <Input width='70%' bg='white' onChange={handleInputChange} name='foreignerAddress' placeholder='Enter Address' value={formData.foreignerAddress} />
          
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Foreigner Address</InputLeftAddon>
           <Input width='70%' bg='white' onChange={handleInputChange} name='foreignerAddressInIndia' placeholder='Address In India' value={formData.foreignerAddressInIndia} />
          
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Purpose</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='purposeOfVisit' placeholder='Enter purpose' value={formData.purposeOfVisit} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>PassportNo</InputLeftAddon>
           <Input  bg='white' onChange={handleInputChange} name='passportNo' placeholder='Enter purpose' value={formData.passportNo} />
           </InputGroup>
           <InputGroup>

            <InputLeftAddon>Issue  Place Passport</InputLeftAddon>
           <Input width='68%' bg='white' onChange={handleInputChange} name='placeIssueOfPassport' placeholder='Passport Issue Place' value={formData.placeIssueOfPassport} />
           </InputGroup>
           <InputGroup>
            <InputLeftAddon>Issue Date Passport</InputLeftAddon>
           <Input width='70%' type='date' bg='white' onChange={handleInputChange} name='dateIssueOfPassport' placeholder='Date Issue Passport' value={formData.dateIssueOfPassport} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Expiry Date Passport</InputLeftAddon>
           <Input bg='white' type='date' onChange={handleInputChange} name='expiryDateOfPassport' placeholder='Expiry Date Passport' value={formData.expiryDateOfPassport} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Visa No</InputLeftAddon>
           <Input bg='white'  onChange={handleInputChange} name='visaNo' placeholder='Visa No' value={formData.visaNo} />
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Visa Type</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='visaType' placeholder='Visa Type' value={formData.visaType} />
           </InputGroup>
          <InputGroup>
          <InputLeftAddon>Place Issue Visa
          </InputLeftAddon>
          <Input bg='white' onChange={handleInputChange}  name='placeIssueOfVisa'  placeholder='Place Issue Visa' value={formData.placeIssueOfVisa} />
          </InputGroup>
          <InputGroup>
          <InputLeftAddon>Expiry Date Visa
          </InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} type='date' name='expiryDateOfVisa'  placeholder='Place Issue Visa' value={formData.expiryDateOfVisa} />
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Arrived From</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='arrivedFrom' placeholder='Enter Arrived From' value={formData.arrivedFrom} />
           </InputGroup>
          
          <InputGroup>
          <InputLeftAddon>Next Destination</InputLeftAddon>
          <Input bg='white' onChange={handleInputChange} name='nextDestination' placeholder='Enter Next Destination' value={formData.nextDestination} />
          </InputGroup>


          <InputGroup>
          <InputLeftAddon>Date of Arrival in India</InputLeftAddon>
          <Input bg='white' type='date' onChange={handleInputChange} name='dateOfArrivalIndia' placeholder='Enter Arrival in India' value={formData.dateOfArrivalIndia} />
          </InputGroup>
          <InputGroup>
          <InputLeftAddon>Date of Arrival Hotel</InputLeftAddon>
          <Input bg='white' type='date' onChange={handleInputChange} name='dateOfArrivalHotel' placeholder=' Date of Arrival Hotel' value={formData.dateOfArrivalHotel} />
          </InputGroup>


          <InputGroup>
          <InputLeftAddon>Time of Arrival Hotel
          </InputLeftAddon>
          <Input bg='white' type='time' onChange={handleInputChange} name='timeOfArrivalHotel' placeholder='Time of Arrival Hotel' value={formData.timeOfArrivalHotel} />
          </InputGroup> 
        <InputGroup>
        <InputLeftAddon>Duration in Hotel</InputLeftAddon>
        <Input bg='white'  onChange={handleInputChange} name='durationInHotel' placeholder='Enter Duration Hotel' value={formData.durationInHotel} />
        </InputGroup>
          <InputGroup>
          <InputLeftAddon>Employed in India?</InputLeftAddon>
          <Select  bg='white' onChange={handleInputChange} name='employedInIndia' placeholder='Employed in India' value={formData.employedInIndia} >
            <option>Yes</option>
            <option>No</option>
          </Select>
          </InputGroup>
           <InputGroup>
           <InputLeftAddon>Permenant Country</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='permanentCountryOfForeigner' placeholder='Enter Permenant Country' value={formData.permanentCountryOfForeigner} />
           
           </InputGroup>
           <InputGroup>
           <InputLeftAddon>Image of Guest</InputLeftAddon>
           <Input bg='white' onChange={handleInputChange} name='imageOfGuest' placeholder='Enter Image' value={formData.imageOfGuest} />
           </InputGroup>
           
         
           

         </SimpleGrid>
         <Box display='flex' alignItems='center' justifyContent='center' mt={2} >
         {/* <Button colorScheme='green' onClick={(e)=>handleSubmit(e)} >Submit</Button> */}
         <Button colorScheme='green' onClick={(e)=>Save(e)} >Save</Button>
         </Box>
         {arr[0] ? <Box mt={10}> <Card mt={10}  textAlign='center'  ref={componentRef}>
          <Box display='flex' justifyContent='center' alignItems='center' mb={5} >
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
      <Text  fontSize='lg' fontFamily='sans-serif' as='b' mt={5}>FORM C</Text>
      <Text  fontSize='sm' fontFamily='sans-serif' mb={2} >(See rule 14)</Text>
      <Text  fontSize='md' as='b' fontFamily='sans-serif' mb={2}>ARRIVAL REPORT OF FOREIGNER IN HOTEL</Text>
      <Text  fontSize='md' fontFamily='sans-serif' >(TO BE COMPLETED IN DUPLICATE)</Text>
      </Box>
     
      </Box>
      <Box position='absolute' top='5%' left='80%' display='flex' justifyContent='center' p={1} alignItems='center' w='100px' h='160px' border='1px solid black'>
        <Image src={formData.imageOfGuest} fontSize='x-small' alt='PHOTOGRAPH OF FOREIGNER'/>
      </Box>
        <CardHeader display='flex' justifyContent='center' alignItems='center' gap={5} p={4}  >
       
        
       
         
        </CardHeader>
          <Box   p={4} textAlign='left'>
         
  {/* <SimpleGrid columns={3} spacing={4}  p={4}>
          {arr.map(([key,value]) => (

            <CardBody   borderRadius="5px" p={4}>
            
            <Text color='purple' fontSize='15px' fontFamily='sans-serif' >{ key[0].toUpperCase() +
              key.slice(1)}--{value}</Text>
             
            
            
              
             
            
            </CardBody>
          ))}
          </SimpleGrid> */}
          <CardBody p={4} display='flex' flexDirection='column'>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>1. Name and address of Hotel or other premises where accomodation has been provided for rewared/on payment : {formData.nameAndAddressOfHotel}</Text>
             
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>2. Purpose of Vist : {formData.purposeOfVisit}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>3. Phone No. / Mobile No. Of Hotel / Place of stay : 
      {formData.phoneOfHotel }
    </Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>4. Date of Birth : {formData.dob}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>5. Address in country where residing / permenantly : {formData.foreignerAddress}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>6. Address / reference in India : {formData.foreignerAddressInIndia}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>7. Nationality : {formData.nationality}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>8. Passport No : {formData.passportNo}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>9. Place of issue of Passport : {formData.placeIssueOfPassport}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>10. Date of issue of Passport : {formData.dateIssueOfPassport}</Text>
              
            </Box>
            
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>11. Valid till : {formData.expiryDateOfPassport}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>12. Visa No : {formData.visaNo}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>13. Date of Issue : {formData.placeIssueOfVisa}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>14. Valid till : {formData.expiryDateOfVisa}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>15. Type of visa : {formData.visaType}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>16. Place if issue : {formData.placeIssueOfVisa}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>17. Arrived From : {formData.arrivedFrom}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>18. Date of arrival in India : {formData.dateOfArrivalIndia}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>19. Date of arrival in Hotel : {formData.dateOfArrivalHotel}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>20. Time of Arrival : {formData.timeOfArrivalHotel}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>21. Intended duration of stay in hotel (No.of days) : {formData.durationInHotel}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>22. Whether employed in India : {formData.employedInIndia}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>23. Purpose of Visit (/) 1.Business 2.Transit 3. Official 4.Employment 5.Education 6.Conference 7.Visit freinds/relatives 8.Medical/Health 9.Religious pilgrimage 10.Leisure/Holiday 11.Sports 12.Others : {formData.purposeOfVisit}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>24. Next Detination / proceed to : {formData.nextDestination}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>25. Contact phone no : {formData.phoneNoOfForeigner}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>26. In country where permenantly residing : {formData.permanentCountryOfForeigner}</Text>
              
            </Box>
            <Box display='flex' justifyContent='left' gap={2}>
              <Text fontSize='sm' as='b'>Note : Please ensure that no column is left / incomplete <br/>
              In case web camera / digital camera in not available photocopy of the photograph in passport may be enclosed.</Text>
              
            </Box>
           
          </CardBody>
          </Box>
        <CardFooter display='flex' justifyContent='space-between' p={25} textAlign='left' >
            <Box as='b' fontSize='15px' fontFamily='sans-serif'>
            Guest Signature:
            </Box>
            <Box mr={20} as='b'  fontSize='15px' fontFamily='sans-serif'>
            <Text>Manager's Signature</Text>
            <Text>Date-</Text>
            </Box>

        </CardFooter>
        
      </Card> </Box>:''}
     {arr[0] ? <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handlePrint}>Print</Button> : ""}
        </ModalBody>

        <ModalFooter>
         
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ForeignReportModal;
