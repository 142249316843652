import React, { useEffect } from "react";
import { VStack, Button, Text, useColorModeValue, Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuItemOption,
  MenuGroup,
  MenuOptionGroup,
  MenuDivider,Popover, PopoverTrigger, PopoverContent, PopoverBody,  Portal } from "@chakra-ui/react";
  import axios from 'axios'
import { Link, useLocation } from "react-router-dom";
import { FaChevronDown } from "react-icons/fa";
import { FaChevronUp } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { MdOutlineBedroomParent } from "react-icons/md";
import { IoFastFoodOutline } from "react-icons/io5";
import { TbCoinRupee } from "react-icons/tb";
import { FaHome } from "react-icons/fa";
import { useState } from "react";

const Sidebar = () => {
  const [data,setData]=useState([])
  const customerId=localStorage.getItem('customerId')
  const fetchData=async()=>{
    const response= await axios.get(`https://api.hotel.tdrsoftware.in/api/getPrintData/${customerId}`)
    setData(response.data)
}
  console.log(customerId);
  const location = useLocation();

  // Function to determine if a menu item is active
  const isActive = (path) => {
    return location.pathname === path;
  };
  useEffect(()=>{
    fetchData()
  },[customerId])

  // Function to apply active style to the menu item
  const getMenuItemStyle = (path) => {
    return isActive(path) ? { background: '#0F172B', color: 'white' } : {};
  };

  return (
    <VStack spacing={4} align="stretch" bg="#FEA116" pt={3}  width="250px" minHeight='1000px'  >
      
         {data.map((item)=>(
          <Text mt={2} mb={4} as='b' fontSize="lg">{item.name}</Text>
         ))}
      
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/dashboard"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/dashboard')}
      >
       <FaHome mr={2} mt={2}/> Dashboard
      </Button>
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/hotel_info"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/hotel_info')}
      >
       <FaHome mr={2} mt={2}/> Hotel Info
      </Button>
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/roles"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/roles')}
      >
       <FaHome mr={2} mt={2}/> Role Create
      </Button>
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/users"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/users')}
      >
       <FaHome mr={2} mt={2}/> User Create
      </Button>
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/')}
      >
       <IoPeopleCircleSharp mr={2} mt={2}/> HallBook Registration
      </Button>
      <Button sx={{ borderRadius: 'none' }}
      bg='transparent'
        as={Link}
        to="/estimate"
        w="full"
        p={2}
       display='flex'
        
        style={getMenuItemStyle('/estimate')}
      >
       <IoPeopleCircleSharp mr={2} mt={2}/> Estmate Billing
      </Button>
      <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/cashRecipt"
      
        variant="ghost"
        style={getMenuItemStyle('/cashRecipt')}
      >
      <TbCoinRupee mr={2} w='6px'/>  Cash Received
      </Button>
      <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/roomBook"
      
        variant="ghost"
        style={getMenuItemStyle('/roomBook')}
      >
       <MdOutlineBedroomParent mr={2} mt={2}/> Room Book
      </Button>
      <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/room"
      
        variant="ghost"
        style={getMenuItemStyle('/room')}
      >
       <MdOutlineBedroomParent mr={2} mt={2}/> Room Registration
      </Button>
      <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/food"
      
        variant="ghost"
        style={getMenuItemStyle('/food')}
      >
      <IoFastFoodOutline mr={2} w='6px'/>  Food-Item Registration
      </Button>
      
      {/* <Button
        as={Link}
        to="/billing"
       
        variant="ghost"
        style={getMenuItemStyle('/billing')}
      >
       Food Billing
      </Button> */}
      {/* <Menu bg='black' h='100px' overflowY='hidden'>
  <MenuButton bg='transparent' as={Button}  rightIcon={<FaChevronDown />}>
    Food Billing
  </MenuButton>
  <MenuList>
    <MenuItem as={Link} to="/billing"
       bg='transparent'
       variant="ghost"
       style={getMenuItemStyle('/billing')}>GST Bill</MenuItem>
    <MenuItem
      as={Link} to="/nonGstBilling"
       bg='transparent'
      variant="ghost"
      style={getMenuItemStyle('/nonGstBilling')}>Non Gst Bill</MenuItem>
    
  </MenuList>
</Menu> */}
      {/* <Button
        as={Link}
        to="/food"
      
        variant="ghost"
        style={getMenuItemStyle('/food')}
      >
        Food-Item Registration
      </Button> */}
      {/* <Button
        as={Link}
        to="/roomBilling"
       
        variant="ghost"
        style={getMenuItemStyle('/roomBilling')}
      >
       Room Billing
      </Button> */}
      {/* <Menu bg='transparent'>
     
    <>
      <MenuButton  bg='transparent'  as={Button} rightIcon={<FaChevronDown />}>
        Room Billing
      </MenuButton>
      <MenuList>
    <MenuItem as={Link} to="/roomBilling"
       
       variant="ghost"
       style={getMenuItemStyle('/roomBilling')}>GST Bill</MenuItem>
    <MenuItem
      as={Link} to="/nonGstRoomBilling"
       
      variant="ghost"
      style={getMenuItemStyle('/nonGstRoomBilling')}>Non Gst Bill</MenuItem>
    
  </MenuList>
    </>
  
  
</Menu> */}
<Popover>
      <PopoverTrigger>
        <Button color='#0F172B' colorScheme="transparent" rightIcon={<FaChevronDown />}>
        <IoFastFoodOutline/>  Food Billing 
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w='100%'>
          <PopoverBody display='flex' flexDirection='column' >
            <Button as={Link} to="/billing"
       bg='transparent'
       variant="ghost"
       style={getMenuItemStyle('/billing')}>Gst Bill</Button>
            <Button as={Link} to="/nonGstBilling"
       bg='transparent'
      variant="ghost"
      style={getMenuItemStyle('/nonGstBilling')}>Non Gst Bill</Button>
            
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
    <Popover>
      <PopoverTrigger>
        <Button color='#0F172B' colorScheme="transparent" rightIcon={<FaChevronDown />}>
         <MdOutlineBedroomParent/> Room Billing 
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent w='100%'>
          <PopoverBody display='flex' flexDirection='column' >
            <Button as={Link} to="/roomBilling"
       bg='transparent'
       variant="ghost"
       style={getMenuItemStyle('/roomBilling')}>Gst Bill</Button>
            <Button as={Link} to="/nonGstRoomBilling"
       bg='transparent'
      variant="ghost"
      style={getMenuItemStyle('/nonGstRoomBilling')}>Non Gst Bill</Button>
            
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </Popover>
    <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/foodBill"
      
        variant="ghost"
        style={getMenuItemStyle('/foodBill')}
      >
      <IoFastFoodOutline mr={2} w='6px'/>  Restaurant Bill
      </Button>
      <Button sx={{ borderRadius: 'none' }}
        as={Link}
        to="/report"
      
        variant="ghost"
        style={getMenuItemStyle('/report')}
      >
      <IoFastFoodOutline mr={2} w='6px'/>  Report Summary
      </Button>
    
    </VStack>
  );
};

export default Sidebar;
