import { Box, Text, Stack, Link, Center } from "@chakra-ui/react";


const Footer = () => {
 
  return (
    <Box
      as="footer"
      bg="#0F172B"
      color="white"
      py={4}
      
      px={{ base: "1rem", md: "2rem" }}
      borderTop="1px"
      
      borderColor="gray.200"
      textAlign="center"
    >
      <Stack
        spacing={3}
        align="center"
        direction={{ base: "column", md: "row" }}
        justify="center"
      >
        {/* Company Name */}
        <Text fontSize="lg" fontWeight="bold">
        TDR Software Private Limited
        </Text>

        {/* Contact Information */}
        <Stack direction="row" spacing={6}>
          <Text>
            <strong>Phone:</strong> +91 9830657184
          </Text>
          <Text>
            <strong>Email:</strong>{" "}
            <Link href="mailto:Caretdr@gmail.com" color="white">
            Caretdr@gmail.com
            </Link>
          </Text>
        </Stack>
      </Stack>

      <Center mt={4}>
        <Text fontSize="sm" color="whiteAlpha.800">
          &copy; {new Date().getFullYear()} TDR Software Private Limited. All rights
          reserved.
        </Text>
      </Center>
    </Box>
  );
};

export default Footer;
