import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    Input,
    Image,
    Select,
    Box,
    Flex,
    Text,Button,SimpleGrid,
    Textarea,
    TableContainer,
    Table,
    Thead,
    Tbody,
    Tr,
    Th,
    Checkbox,
    Td,
    TableCaption,
    AlertDialog,
    AlertDialogBody,
    AlertDialogFooter,
    AlertDialogHeader,
    AlertDialogContent,
    AlertDialogOverlay,
    useToast
  } from "@chakra-ui/react";
  import { useSelector } from "react-redux";
  
  import { Card, CardHeader, CardBody, CardFooter } from '@chakra-ui/react'
  import { useRef } from "react";
  import { useReactToPrint } from "react-to-print";
  
  import axios from 'axios'
  
  import React, { useState,useEffect } from "react";
  import toast from "react-hot-toast";

import { useLocation } from "react-router-dom";
  const array = [
    "Malda",
    "Uttar Dinajpur",
    "Dakshin Dinajpur",
    "Murshidabad",
    "Birbhum",
    "Hoogly",
    "Paschim Bardhaman",
    "Purba Bardhaman",
    "Alipurduar",
    "Cooch Behar",
    "Darjeeling",
    "Jalipaiguri",
    "Kalingpong",
    "Howrah",
    "Kolkata",
    "Nadia",
    "North 24 Pargana",
    "South 24 Pargana",
    "Bankura",
    "Jhargram",
    "Purulia",
    "Purba Medinipur",
    "Paschim Medinipur",
  ];
  
  let fiterArray = array.sort((a, b) => {
    return a.localeCompare(b);
  });
  
  //console.log(fiterArray);
  const RoleCreate = () => {
  
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
      content: () => componentRef.current,
    });
    const customerId=localStorage.getItem('customerId')

    const token=localStorage.getItem('token');
    const [data,setData]=useState([])
    const [isEditing, setIsEditing] = useState(false);
    const [editItemId, setEditItemId] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [deleteItemId, setDeleteItemId] = useState(null);
    const [role,setRole]=useState([])
    const cancelRef = useRef();
    const [formData, setFormData] = useState({
      role: "",
      roomRoutes:false,
      cashRoutes:false,
      billRoutes:false,
      inventoryRoutes:false,
      foreignerReportRoutes:false
    
      
    });
  

 

  //const customerId=localStorage.getItem('customerId');



  const fetchData=async()=>{
    try {
      const response=await axios.get(`https://api.hotel.tdrsoftware.in/api/getRoles/${customerId}`)
      setData(response.data.role)
      //console.log(response.data.user)
    } catch (error) {
      toast.error(error.message)
    }
  }
const edit=(item)=>{
  setIsEditing(true)
  setEditItemId(item._id)
  setFormData({
    role:item.role,
    roomRoutes:item.roomRoutes,
    cashRoutes:item.cashRoutes,billRoutes:item.billRoutes,
    inventoryRoutes:item.inventoryRoutes,foreignerReportRoutes:item.foreignerReportRoutes
  })
}
console.log(formData,"formData")
  
    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setFormData({
        ...formData,
        [name]: value,
      });
    };
    const handleCheckboxChange = (e) => {
        const { name, checked } = e.target;
        setFormData((prevFormData) => ({
          ...prevFormData,
          [name]: checked, // Set formData.halfPlate to true/false based on checkbox state
        }));
      };

useEffect(()=>{
  fetchData()
 
},[customerId])

  
    const validateForm = () => {
      const requiredFields = [
        "role",
        
        
        
     
        
      ];
  
      for (const field of requiredFields) {
        if (!formData[field]) {
          return false;
        }
      }
      return true;
    };
//  const handleSubmit=async(e)=>{
//     e.preventDefault();
//     if(!validateForm()) {
//       toast.error("Please fill in all required fields.");
//       return;
//     }
//     setFormData({
//         name:formData.name,
//         address:formData.address,
//         phoneNo:formData.phoneNo,
//         gstIn:formData.gstIn,
//         openingBalance:formData.openingBalance,
//     })

//  }

    const save=async(e)=>{
        e.preventDefault();
        if(!validateForm()) {
          toast.error("Please fill in all required fields.");
          return;
        }
        try{
       if(isEditing){
        
          const response=await axios.put(`https://api.hotel.tdrsoftware.in/api/updateRoles/${customerId}/${editItemId}`,{data:formData})
          toast.success(response.data.message)
          setIsEditing(false)
          setEditItemId(null)
          
        
       }else{
        const response=await axios.post("https://api.hotel.tdrsoftware.in/api/createRole",{
          customerId:customerId,data:formData
        });
       // console.log('Data Submitted: ',response.data);
        
        toast.success(response.data.message)
      }
        setFormData({
          role: "",
          roomRoutes:false,
          cashRoutes:false,
          billRoutes:false,
          inventoryRoutes:false,
          foreignerReportRoutes:false
        })
        fetchData()

      }catch(error){
        toast.error(error.meesage)
      }
    }
  //  console.log(formData,'formdata');
    
   // console.log(arr)
   
    //console.log(newObj);
   
    const openModal = (id) => {
      setDeleteItemId(id);
      setIsOpen(true);
    };
  
    const onClose = () => {
      setIsOpen(false);
      setDeleteItemId(null);
    };
    const handleDelete=async()=>{
     
      try {
        const response=await axios.delete(`https://api.hotel.tdrsoftware.in/api/deleteRole/${customerId}/${deleteItemId}`)
        toast.success(response.data.message)
        setIsOpen(false)
        setDeleteItemId(null)
        fetchData()
      } catch (error) {
        toast.error(error.message)
      }
    }
    
    // due=total-advance
    return (
      <Box   bg='#EAE5DF '
      columns={{ sm: 2, md: 4 }}
      spacing='8'
      p='10'
      textAlign='center'
      
      
      color='black' width='100%' height='500px'   >
      <Text fontSize='2xl' fontWeight='bold' textAlign='center' color='black' mb={10}>Role Create</Text>
        
        <FormControl isRequired onSubmit={save}>
          <Box display="grid"  gridGap={1} >
        
            
            <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="40%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                <FormLabel fontSize='sm'>Role</FormLabel>
                <Input size='sm' w='65%'  bg='#FAFAFA ' placeholder="Enter Name" name='role' value={formData.role} onChange={handleInputChange}/>
              </Box>
             
             
              </Box>
              <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                {/* <FormLabel fontSize='sm'>RoomRoutes</FormLabel> */}
                <Checkbox w='90%' bg='#fafafa' isChecked={formData.roomRoutes} name='roomRoutes' onChange={handleCheckboxChange}>Room Routes</Checkbox>
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                {/* <FormLabel fontSize='sm'>BillRoutes</FormLabel> */}
                <Checkbox w='90%' bg='#fafafa' isChecked={formData.billRoutes} name='billRoutes' onChange={handleCheckboxChange}>Bill Routes</Checkbox>
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl ' 
              borderRadius="5px"
              p={4}>
                {/* <FormLabel fontSize='sm'>CashRoutes</FormLabel> */}
                <Checkbox w='90%' bg='#fafafa' isChecked={formData.cashRoutes} name='cashRoutes' onChange={handleCheckboxChange}>Cash Routes</Checkbox>
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                {/* <FormLabel fontSize='sm'>InventoryRouts</FormLabel> */}
                <Checkbox w='90%' bg='#fafafa' isChecked={formData.inventoryRoutes} name='inventoryRoutes' onChange={handleCheckboxChange}>Inventory Routes</Checkbox>
              </Box>
              <Box width="20%" display="flex" alignItems="center"  boxShadow='xl'
              borderRadius="5px"
              p={4}>
                {/* <FormLabel fontSize='sm'>ForeignerReportRoutes</FormLabel> */}
                <Checkbox w='90%' bg='#fafafa' isChecked={formData.foreignerReportRoutes} name='foreignerReportRoutes' onChange={handleCheckboxChange}>Foreigner Routes</Checkbox>
              </Box>
             
             
              </Box>
              <Box
              display="flex"
              gap="10px"
              alignItems="center"
              justifyContent="space-evenly"
              
            >
             
             
             
              </Box>
              
             
            
            
            
            
            
              
             
           
          
          </Box>
         
          {/* <Button type="submit" variant='solid' color='#0F172B' mr={3} bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={handleSubmit}>Cash Bill</Button> */}
          <Button type="submit" variant='solid' color='#0F172B' bg='#FEA116' _hover={{color:'white'}} mt={5} onClick={save}>{isEditing ? "Update" : "Save"}</Button>
         
        </FormControl>
        <TableContainer width='full' mt={10} border='1px solid black' p={4} bg='white' borderRadius='10px' fontSize='sm'>
                <Table size='sm' variant='striped' colorScheme='teal'>
                    <TableCaption>Item Data</TableCaption>
                    <Thead>
                        <Tr >
                            <Th >Role</Th>
                            <Th>RoomRoutes</Th>
                            <Th>CashRoutes</Th>
                            <Th>BillRoutes</Th>
                            <Th>InventoryRoutes</Th>
                            <Th>ForeignerReportRoutes</Th>
                            
                            
                            <Th>Edit</Th>
                            <Th>Delete</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        {data?.map((item, index) => (
                            <Tr key={index}>
                                <Td>{item.role}</Td>
                                <Td>{item.roomRoutes? "Yes" : "No"}</Td>
                                <Td>{item.cashRoutes ? "Yes" : "No"}</Td>
                                <Td>{item.billRoutes ? "Yes" : "No"}</Td>
                                <Td>{item.inventoryRoutes ? "Yes" : "No"}</Td>
                                <Td>{item.foreignerReportRoutes ? "Yes" : "No"}</Td>
                               
                                
                                
                                <Td><Button colorScheme="green" size="sm" onClick={() => edit(item)}>Edit</Button></Td>
                                <Td><Button colorScheme="red" size="sm" onClick={() => openModal(item._id)}>Delete</Button></Td>

                            </Tr>
                        ))}
                    </Tbody>
                </Table>
            </TableContainer>
            <AlertDialog isOpen={isOpen} leastDestructiveRef={cancelRef} onClose={onClose}>
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Confirm Deletion
            </AlertDialogHeader>

            <AlertDialogBody>Are you sure you want to delete this item?</AlertDialogBody>

            <AlertDialogFooter>
              <Button ref={cancelRef} onClick={onClose}>
                Cancel
              </Button>
              <Button colorScheme="red" onClick={handleDelete} ml={3}>
                Delete
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
      
      
        
      </Box>
    );
  };
  
  export default RoleCreate;
  